import React from "react";
import styled from 'styled-components'
import { Link } from "gatsby"

const IconSquare = ( { icon, text, url } ) => {

    const IconSquare = styled(Link)`
       height: 101px;
       border-radius: 16px;
       background-color: #e3e3e3;
       width: 100%;
       margin: 0px 4px;
       margin-top: 16px;
       position: relative;
    `;

    const IconContainer = styled.div`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    `

    const Icon = styled.div`
        height: 36px;
        width: 36px;
        display: block;
        margin: auto;
    `

    const Text = styled.span`
        color: #666;
        font-size: 14px;
        margin-top: 4px;
        display: block;
        text-align: center;
    `

    return (
        <>
            <IconSquare to={ url }>
                <IconContainer>
                    <Icon>{icon}</Icon>
                    <Text>{ text }</Text>
                </IconContainer>
            </IconSquare>
        </>
    )
}

export default IconSquare
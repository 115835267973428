import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Button = ({ text, url, style, disabled = false, target }) => {
    const Button = styled(Link)`
        background-color: #e3e3e3;
        height: 48px;
        width: 100%;
        border-radius: 16px;
        display: block;
        margin-top: 8px;
        position: relative
    `;

    const Text = styled.span`
        font-size: 14px;
        color: #323232;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Source Serif Pro', serif
    `
    return (
        <Button to={url} style={style} disabled={disabled} target={target}>
            <Text>{text}</Text>
        </Button>
    )
}

export default Button

import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import PageTitle from '../components/pageTitle'
import IconSquare from '../components/iconsquare'
import Couple from '../components/icons/couple'
import Church from '../components/icons/church'
import Plane from '../components/icons/plane'
import Button from '../components/button'
import TextBlock from '../components/textBlock'

const QuickLinksContainer = styled.div ({
  backgroundColor: '#fff',
  padding: '24px 8px',
  height: '282px',
  boxSizing: 'border-box',
  width: 'calc(100% - 32px)',
  display: 'block',
  margin: 'auto',
  'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '16px',
  marginTop: '-141px',
  position: 'relative',
  'line-height' : '0px !importnat',
  'z-index': '10',
})

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    hero: file(relativePath: { eq: "Engagement-61.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <Layout>
      <SEO title="Home" />
      <Hero file={data.hero.childImageSharp.fluid} />
      <QuickLinksContainer>
          <PageTitle
            pageTitle="Samantha & Tony"
            subHeadline="May 29, 2021 • Niagara Falls, NY"
          />
          <div style={{width: '100%', display: 'flex'}}>
            <IconSquare text={ "Our Story" } icon={<Couple size={36}/>} url="/our-story"/>
            <IconSquare text={ "Wedding Day" } icon={<Church size={36}/>} url="/wedding-day"/>
            <IconSquare text={ "Travel" } icon={<Plane size={36}/>} url="/travel" />
          </div>
          <Button text="Wedding Registry" url="/registry"/>
      </QuickLinksContainer>
      <div style={{width: 'calc(100% - 32px)', display: 'block', margin: '24px auto'}}>
        <TextBlock title="COVID-19" subTitle="Current Updates and Protocols">
          <p>All rules are subject to change based on NYS orders.</p>
          <p>Masks will be required at all times except when seated and eating or drinking.</p>
          <p>Ceremonial and socially-distanced dancing allowed under strict guidelines.</p>
          <p>No testing will be required prior to the event.</p>
        </TextBlock>
      </div>

    </Layout>
  )
}

export default IndexPage

import React from 'react'
import styled from 'styled-components'

const PageTitle = ( { pageTitle, subHeadline } ) => {
    const PageTitle = styled.h1`
       font-family: 'Lato';
       font-size: 24px;
       font-weight: 900;
       line-height: 24px;
       color: #323232;
       text-align: center;
       margin: 0px;
       height: 24px;
       display: block;
    `;
    const SubHeadline = styled.h2`
       font-family: 'Source Serif Pro';
       font-size: 14px;
       height: 14px;
       margin-top: 12px;
       font-weight: 400;
       line-height: 14px;
       color: #666;
       text-align: center;
    `;
    return (
        <>
            <PageTitle>
                { pageTitle }
            </PageTitle>
            { subHeadline && <SubHeadline>{ subHeadline }</SubHeadline> }
        </>
    )
}

export default PageTitle